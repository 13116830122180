


































import { Component, Vue, Prop } from "vue-property-decorator";
import BaseField from "@/components/Shared/Table/BaseField.vue";
import RadialProgressBar from "vue-radial-progress";
import { BopStatus } from "@/utils/Enums";

@Component({
  components: { RadialProgressBar },
})
export default class StatusField extends BaseField {
  @Prop({ required: true }) public props!: any;

  get key() {
    return this.getByProperty("bopStatusKey");
  }

  get attributes() {
    const attributes = {
      completedStep: 0,
      isDeclined: false,
    };
    switch (this.key) {
      case BopStatus[BopStatus.Initial]:
        attributes.completedStep = 1;
        attributes.isDeclined = false;
        break;
      case BopStatus[BopStatus.Simulation]:
        attributes.completedStep = 1;
        attributes.isDeclined = false;
        break;
      case BopStatus[BopStatus.Completed]:
        attributes.completedStep = 2;
        attributes.isDeclined = false;
        break;
      case BopStatus[BopStatus.WaitingForValidation]:
        attributes.completedStep = 2;
        attributes.isDeclined = false;
        break;
      case BopStatus[BopStatus.AcceptLunchWork]:
        attributes.completedStep = 3;
        attributes.isDeclined = false;
        break;
      case BopStatus[BopStatus.Finished]:
        attributes.completedStep = 4;
        attributes.isDeclined = false;
        break;
      case BopStatus[BopStatus.OkForReception]:
        attributes.completedStep = 4;
        attributes.isDeclined = false;
        break;
      case BopStatus[BopStatus.Archived]:
        attributes.completedStep = 4;
        attributes.isDeclined = false;
        break;
      case BopStatus[BopStatus.Declined]:
        attributes.isDeclined = true;
        attributes.completedStep = 4;
        break;
      default:
        break;
    }
    return attributes;
  }

  mounted() {}
}
