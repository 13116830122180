



















































import { Component, Vue, Prop } from "vue-property-decorator";
import BaseField from "@/components/Shared/Table/BaseField.vue";
import { BopModelEnum, BopStatus, UserRoleEnum } from "@/utils/Enums";
import router from "@/router";
import BopService from "@/services/BopService";
import BopStatusService from "@/services/BopStatusService";
import Constants from "@/utils/Constants";

@Component({
  components: {},
})
export default class ActionBopAccueilField extends BaseField {
  private isAction: boolean = false;
  private get bopStatusKey(): string {
    return this.getByProperty("bopStatusKey");
  }
  private get isCreatedBySap(): Boolean {
    return this.getByProperty("isCreatedBySap");
  }
  private label: string = "";

  private get computedMailCondition() {
    return (
      this.props.rowField.sendMail &&
      this.props.rowData.bopModelKey &&
      this.props.rowData.bopModelKey == BopModelEnum.Echafaudage &&
      (this.bopStatusKey === BopStatus.Finished || this.bopStatusKey === BopStatus.AcceptLunchWork)
    );
  }
  // changes bop status to declined
  private get canBeCanceled(): boolean {
    return (this.bopStatusKey === BopStatus.WaitingForValidation) || (this.bopStatusKey === BopStatus.AcceptLunchWork && this.isCreatedBySap == false);
  }
  private get userHaveTheRightPrivilege(): boolean{
    return [UserRoleEnum.centralAdministrator,UserRoleEnum.countryAdministrator,UserRoleEnum.siteAdministrator].includes(this.$store.getters.user.role.role.key);
  }
  private get computedTransformCondition() {
    return (
      this.props.rowField.transformBop &&
      this.props.rowData.bopStatusKey &&
      this.props.rowData.bopStatusKey == BopStatus.Simulation
    );
  }
  private get computedMailHover() {
    
    let result = this.$t('info.mail.title');
    const initSentDate = this.getByProperty('initDismantleMailDate');
    if(initSentDate !== null && initSentDate !== undefined) {
      result += `, ${this.$t('info.mail.initSent')} ${this.$d(Date.parse(initSentDate), 'long')}`;
    }

    const lastSentDate = this.getByProperty('lastDismantleMailDate');
    if(lastSentDate !== null && lastSentDate !== undefined) {
      result += `, ${this.$t('info.mail.lastSent')} ${this.$d(Date.parse(lastSentDate), 'long')}`;
    }

    return result;
  }

  private get isEditable(): boolean {
    return this.bopStatusKey === BopStatus.Finished ||this.bopStatusKey === BopStatus.Archived;
  }
  private get isRowClickTemporaryDisabled(): boolean {
    return this.$store.state.isRowClickTemporaryDisabled;
  }
  private set isRowClickTemporaryDisabled(value: boolean) {
    this.$store.commit("changeIsRowClickTemporaryDisabled", value);
  }
  private get statuses():Object{
    return this.$store.state.statuses;
  }
  private async setBopStatus() {
    this.isRowClickTemporaryDisabled = true;
    try {
      const modalResponse = await this.$bvModal.msgBoxConfirm(
        this.$t("bopConfirmChangeStatus.message").toString(),
        {
          title: this.$t("bopConfirmChangeStatus.title").toString(),
          size: "lg",
          buttonSize: "lg",
          okVariant: "primary",
          cancelVariant: "secondary",
          okTitle: this.$t("bopConfirmChangeStatus.yes").toString(),
          cancelTitle: this.$t("bopConfirmChangeStatus.no").toString(),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
          dialogClass: "modal-app",
        }
      );
      if (modalResponse) {
        const bopId = this.props.rowData.id;
        const response = await BopService.setBopToInitialState(bopId);
        if (response.status === 200) {
          this.$notify({
            group: "global",
            type: "success",
            title: this.$t("bop.changeStatusSuccessTitle").toString(),
            text: this.$t("bop.changeStatusSuccessMessage").toString(),
          });
          
          this.props.rowData.bopStatusKey = BopStatus.Initial;
          this.props.rowData.bopStatusName = this.statuses[BopStatus.Initial];
          // (<any>this.$parent).reload();
        }
      }
    } catch {
      this.$notify({
        group: "globalError",
        type: "error",
        title: this.$t("bop.changeStatusErrorTitle").toString(),
        text: this.$t("bop.changeStatusErrorMessage").toString(),
        duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
      });
    }
  }
  private async sendMail() {
    this.isRowClickTemporaryDisabled = true;
    const response = await BopService.mailDismantleBop(this.props.rowData.id);

    // update date without reloading the page
    if(response.data !== "") {
      if(!this.props.rowData.initDismantleMailDate) this.props.rowData.initDismantleMailDate = response.data;
      this.props.rowData.lastDismantleMailDate = response.data;
      this.$notify({
        group: "global",
        type: "success",
        title: this.$t("info.mail.success.dismantleMailTitle").toString(),
        text: this.$t("info.mail.success.dismantleMailText").toString(),
      });
    } else {
      this.$notify({
        group: "globalError",
        type: "error",
        title: this.$t("info.mail.error.dismantleMailTitle").toString(),
        text: this.$t("info.mail.error.dismantleMailText").toString(),
      });
    }
  }
  private async cancelBop() {
    this.isRowClickTemporaryDisabled = true;
    const modalResponse = await this.$bvModal.msgBoxConfirm(
      router.app.$t("modal.cancelBopMessage").toString(),
      {
        title: router.app.$t("modal.cancelBopTitle").toString(),
        size: "lg",
        buttonSize: "lg",
        okVariant: "primary",
        cancelVariant: "secondary",
        okTitle: router.app.$t("modal.cancelBopYes").toString(),
        cancelTitle: router.app.$t("modal.cancelBopNo").toString(),
        dialogClass: "modal-app",
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      }
    );
    if (modalResponse) {
      try {
        const bopId = this.props.rowData.id;
        const response = await BopService.cancelBop(bopId);
        if (response.status === 200) {
          this.props.rowData.bopStatusKey = BopStatus.Declined;
          this.props.rowData.bopStatusName = this.statuses[BopStatus.Declined];
        }
      } catch {}
    }
  }
  private modify() {
    this.isRowClickTemporaryDisabled = true;
    if (
      this.props.rowData.bopModelKey === BopModelEnum[BopModelEnum.Echafaudage]
    ) {
      router.push({
        name: "BopSetupUpdate",
        params: { bopId: this.props.rowData.id },
        query: { index: "2", isEdit: "true" },
      });
    } else {
      router.push({
        name: "BopSetupUpdate",
        params: { bopId: this.props.rowData.id },
        query: { index: "1", isEdit: "true" },
      });
    }
  }
}
